var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg_box" }, [
        !_vm.isShowPage ? _c("div", [_vm._m(0)]) : _vm._e()
      ]),
      _vm.isShowPage
        ? _c(
            "div",
            { staticClass: "personbox" },
            [
              _c("van-nav-bar", {
                attrs: {
                  title: "我的",
                  "safe-area-inset-top": "",
                  fixed: "",
                  placeholder: "",
                  border: false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "right",
                      fn: function() {
                        return [
                          _c("van-icon", {
                            attrs: { name: "setting-o" },
                            on: { click: _vm.loginout }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2273625248
                )
              }),
              _c("div", { staticClass: "rel" }, [
                _c("div", { staticClass: "phonttop" }),
                _c("div", { staticClass: "abs topbox" }, [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "imgbox" },
                      [
                        _vm.uerinfo.avatar
                          ? [
                              _c("img", {
                                attrs: { src: _vm.uerinfo.avatar, alt: "" }
                              })
                            ]
                          : [
                              _vm.uerinfo.realName
                                ? [
                                    _c("img", {
                                      attrs: { src: _vm.processEnv, alt: "" }
                                    })
                                  ]
                                : [
                                    _vm.uerinfo.auth == "UNPOST"
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.processEnv,
                                            alt: ""
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.uerinfo.auth == "POST"
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.processEnv,
                                            alt: ""
                                          }
                                        })
                                      : _vm._e()
                                  ]
                            ]
                      ],
                      2
                    ),
                    _c("div", { staticClass: "namebox" }, [
                      _c("div", [
                        _c("div", { staticClass: "postBox" }, [
                          _vm.uerinfo.realName
                            ? _c("p", [_vm._v(_vm._s(_vm.uerinfo.realName))])
                            : _vm._e(),
                          _vm.uerinfo.auth == "UNPOST" ||
                          _vm.uerinfo.auth == "FAIL"
                            ? _c(
                                "div",
                                {
                                  attrs: { "data-info": "certification" },
                                  on: { click: _vm.isAutoGraph }
                                },
                                [_vm._m(1)]
                              )
                            : _vm.uerinfo.auth == "POST"
                            ? _c("div", [_vm._m(2)])
                            : _vm.uerinfo.auth == "DONE"
                            ? _c("div", [
                                !_vm.uerinfo.hasOwnProperty("sign")
                                  ? _c(
                                      "div",
                                      { staticClass: "pleaseCertify" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name: "AccountContract",
                                                query: { pricing: true }
                                              }
                                            }
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/btn_post_examine@2x.png"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : !_vm.uerinfo.hasOwnProperty("faceVerify")
                                  ? _c(
                                      "div",
                                      { staticClass: "pleaseCertify" },
                                      [
                                        _c(
                                          "router-link",
                                          { attrs: { to: { name: "face" } } },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/btn_post_examine@2x.png"),
                                                alt: ""
                                              }
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "pleaseCertify" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/btn_post_examine@2x.png"),
                                            alt: ""
                                          }
                                        })
                                      ]
                                    )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("p", { staticClass: "phone" }, [
                        _vm._v(_vm._s(_vm.uerinfo.phone))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "waitHandleBox flex-between" }, [
                    _c("div", { staticClass: "waitHandleDescribe" }, [
                      _vm._v("待处理")
                    ]),
                    _c(
                      "div",
                      { staticClass: "waitHandle", on: { click: _vm.openBuy } },
                      [
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(_vm.buyMaterials ? _vm.buyMaterials : 0)
                          )
                        ]),
                        _c("div", [_vm._v("买料订单")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "waitHandle",
                        on: { click: _vm.openSell }
                      },
                      [
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(_vm.sellMaterials ? _vm.sellMaterials : 0)
                          )
                        ]),
                        _c("div", [_vm._v("卖料订单")])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "waitHandle",
                        on: { click: _vm.openMortgage }
                      },
                      [
                        _c("div", { staticClass: "number" }, [
                          _vm._v(
                            _vm._s(
                              _vm.mortgageMaterials ? _vm.mortgageMaterials : 0
                            )
                          )
                        ]),
                        _c("div", [_vm._v("存料订单")])
                      ]
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "topcenter" }, [
                _c("div", { staticClass: "box" }, [
                  _c("h1", [_vm._v("常用功能")]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(0)
                          }
                        }
                      },
                      [_vm._m(3), _c("span", [_vm._v("卖料订单")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(1)
                          }
                        }
                      },
                      [_vm._m(4), _c("span", [_vm._v("买料订单")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(2)
                          }
                        }
                      },
                      [_vm._m(5), _c("span", [_vm._v("存料订单")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(3)
                          }
                        }
                      },
                      [_vm._m(6), _c("span", [_vm._v("押金")])]
                    )
                  ]),
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(4)
                          }
                        }
                      },
                      [_vm._m(7), _c("span", [_vm._v("提料管理")])]
                    ),
                    _c("div", { staticClass: "iconBox" }, [
                      _c(
                        "div",
                        {
                          staticClass: "imgminbox",
                          on: {
                            click: function($event) {
                              return _vm.linkFunciton(5)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/icon_statement.png"),
                              alt: ""
                            }
                          })
                        ]
                      ),
                      _c("span", [_vm._v("结算")])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(6)
                          }
                        }
                      },
                      [_vm._m(8), _c("span", [_vm._v("订单报表")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "iconBox",
                        on: {
                          click: function($event) {
                            return _vm.linkFunciton(7)
                          }
                        }
                      },
                      [_vm._m(9), _c("span", [_vm._v("来料管理")])]
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(8)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_remind.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("价格提醒")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2153443299
                      )
                    }),
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(0)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_express@2x.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("快递查询")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1773341
                      )
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "box" },
                  [
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(6)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_business@2x.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("业务说明")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        294872222
                      )
                    }),
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(2)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_ condition@2x.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("回收标准")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3966840003
                      )
                    }),
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(3)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_bank card@2x.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("银行账号")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        759615488
                      )
                    }),
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(4)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_wenhao@2x.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("常见问题")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3669669708
                      )
                    }),
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(9)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_about.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("邀请好友")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2540546911
                      )
                    }),
                    _c("van-cell", {
                      staticClass: "vanCell",
                      attrs: { border: false },
                      on: {
                        click: function($event) {
                          return _vm.link(7)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c("img", {
                                  staticClass: "guidepost-icon",
                                  attrs: {
                                    src: require("@/assets/images/icon_about @2x.png")
                                  }
                                }),
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v("关于我们")
                                ])
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "right-icon",
                            fn: function() {
                              return [
                                _c("van-icon", {
                                  staticClass: "search-icon",
                                  attrs: { name: "arrow" }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3951168884
                      )
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-dialog",
        {
          model: {
            value: _vm.cershow,
            callback: function($$v) {
              _vm.cershow = $$v
            },
            expression: "cershow"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v("您的账号还未认证，暂无此项权限")
          ]),
          _c(
            "div",
            { staticClass: "gocer flex-around" },
            [
              _c(
                "div",
                {
                  staticClass: "canclebox",
                  on: {
                    click: function($event) {
                      _vm.cershow = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c("router-link", { attrs: { to: { name: "certification" } } }, [
                _vm._v("去认证")
              ])
            ],
            1
          )
        ]
      ),
      _c(
        "van-dialog",
        {
          model: {
            value: _vm.ceringModel,
            callback: function($$v) {
              _vm.ceringModel = $$v
            },
            expression: "ceringModel"
          }
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("提示")]),
          _c("div", { staticClass: "centerbox_dialog" }, [
            _vm._v("您的认证信息正在审核中，请稍后查看")
          ]),
          _c(
            "div",
            {
              staticClass: "gocer",
              on: {
                click: function($event) {
                  _vm.ceringModel = false
                }
              }
            },
            [_vm._v("确定")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loading" }, [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span"),
      _c("span")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/btn_authentication@2x.png"),
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pleaseCertify" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/btn_examine@2x.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon_sell@2x.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon_buy@2x.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon_stock.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon_money@2x.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon_material.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon_orderData.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgminbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/icon_incoming.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }